.main {
  max-width: 1200px;
  margin: 25px auto;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 10px;
}
